<style lang="scss" scoped>
@import "@assets/styles/variables.scss"; //公共样式变量
</style>

<template>
  <!-- 用户管理 -->
  <section class="page-child-contianer">
    <div class="table-box">
      <!-- 搜索表单 -->
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="用户姓名：">
          <el-input v-model="searchForm.name" placeholder="用户姓名"></el-input>
        </el-form-item>
        <el-form-item label="用户账号：">
          <el-input
            v-model="searchForm.account"
            placeholder="用户账号"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户电话：">
          <el-input
            v-model="searchForm.mobile"
            placeholder="用户电话"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="handleSearch" icon="el-icon-search">
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <!-- 数据内容 -->
      <div class="table-container" id="tableContainer">
        <!-- 内页工具栏 -->
        <div class="tool-bar">
          <div class="tool-left">
            <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="$router.push({ name: 'MEMBER_ADD' })"
            >
              添加
            </el-button>
            <el-button
              size="small"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete()"
            >
              删除
            </el-button>
          </div>
          <div class="tool-right">
            <el-tooltip effect="dark" content="刷新">
              <el-button
                size="small"
                icon="el-icon-refresh-right"
                @click="handleRefresh"
              />
            </el-tooltip>
            <el-tooltip effect="dark" content="全屏">
              <el-button size="small" @click="handleFullScreen">
                <span class="iconfont">
                  {{ (isFull && "&#xe641;") || "&#xe8fa;" }}
                </span>
              </el-button>
            </el-tooltip>
          </div>
        </div>
        <div class="table-data" ref="tableContainer">
          <!-- 内页表格数据 -->
          <el-table
            v-loading="tableLoading"
            ref="tableBox"
            border
            style="width: 100%"
            :max-height="tableHeight"
            :data="tableData"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
              fixed="left"
            />
            <el-table-column prop="id" label="ID" width="80" align="center" />
            <el-table-column
              prop="name"
              label="账号昵称"
              width="180"
              align="center"
            />
            <el-table-column
              prop="account"
              label="账号"
              width="180"
              align="center"
            />
            <el-table-column
              prop="account"
              label="头像"
              width="180"
              align="center"
            >
              <template slot-scope="scope">
                <el-avatar
                  shape="square"
                  :size="25"
                  fit="cover"
                  :src="scope.row.thumb_path_source"
                >
                  <img
                    src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
                  />
                </el-avatar>
              </template>
            </el-table-column>
            <el-table-column
              prop="mobile"
              label="联系电话"
              width="180"
              align="center"
            />
            <el-table-column label="状态" width="180" align="center">
              <template slot-scope="scope">
                <el-tooltip :content="scope.row.status_name" placement="top">
                  <el-switch
                    v-model="scope.row.status"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-value="1"
                    inactive-value="2"
                    @change="handleStatusUpdate(scope.row.id, scope.row.status)"
                  >
                  </el-switch>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" width="210" align="center">
              <template slot-scope="scope">
                <p>
                  {{
                    (scope.row.create_time * 1000)
                      | formatTime("YYYY-MM-DD HH:mm:ss")
                  }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="240"
              align="center"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button
                  class="color-primary"
                  type="text"
                  icon="el-icon-edit"
                  @click="
                    $router.push({
                      name: 'MEMBER_EDIT',
                      params: { id: scope.row.id },
                    })
                  "
                >
                  编辑
                </el-button>
                <el-button
                  class="color-success"
                  type="text"
                  icon="el-icon-key"
                  @click="handleResetPwd(scope.row.id)"
                >
                  重置密码
                </el-button>
                <el-button
                  class="color-danger"
                  type="text"
                  icon="el-icon-delete"
                  @click="handleDelete(scope.row.id)"
                >
                  删除
                </el-button>
                <el-button
                  class="color-success"
                  type="text"
                  v-if="scope.row.login_status == 2"
                  icon="el-icon-unlock"
                  @click="handleLoginStatus(scope.row.id)"
                >
                  解冻
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页控件 -->
        <el-pagination
          background
          layout="total,sizes,prev, pager, next,jumper"
          :current-page="pagination.current"
          :page-size="pagination.size"
          :page-sizes="pagination.sizes"
          :total="pagination.total"
          @size-change="handleSize"
          @current-change="handleCurrent"
        >
        </el-pagination>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import {
  getMemberList,
  memberDelete,
  memberUpdate,
  loginStatusUpdate,
} from "@api/member"; // 引用./src/api/member.js中的'getMemberList'方法
import { PAGE_SIZE, PAGE_SIZES } from "@config";
export default {
  name: "member",
  inject: ["reload"],
  data() {
    return {
      tableLoading: false,
      tableHeight: 0, //表格最大高度
      searchForm: {}, //搜索表单
      isFull: false, //全屏开启
      tableData: [], //表格数据
      multipleSelection: [], //表格多选储存数据
      smallRoleObj: {},
      statusValue: 1,
      pagination: {
        page: 1,
        total: 0,
        current: 1,
        size: PAGE_SIZE,
        sizes: PAGE_SIZES,
      }, //分页数据
    };
  },
  computed: {
    ...mapState("system", ["branchListSearch"]),
  },
  created() {
    // 获取初始化数据

    let tempParams = {
      page: this.pagination.page,
      rows: this.pagination.size,
    };
    this.getData(tempParams);
    /**
     * 页面dom元素加载完后调用，计算表格最大高度
     */
    this.$nextTick(function () {
      this.initTableHeight();
    });
  },
  async mounted() {
    window.addEventListener("resize", () => {
      // 窗口尺寸改变时触发事件
      if (!this.$screenfull.isFullscreen) {
        this.isFull = false;
      }
      this.initTableHeight();
    });
    this.smallRoleObj = await this.validateSmallRole();
    this.$forceUpdate();
  },
  methods: {
    /** 获取表格数据 */
    async getData(tempParams) {
      this.tableLoading = true;
      tempParams = {
        page: this.pagination.page,
        rows: this.pagination.size,
        ...this.searchForm,
      };
      /**
       * 请求接口数据
       */
      let res = await getMemberList(tempParams);
      this.tableData = res.data.list;

      this.pagination.total = res.data.count;
      this.tableLoading = false;
      this.$forceUpdate();
    },
    /** 分页参数-每页显示条数改变 */
    handleSize(val) {
      this.pagination = {
        ...this.pagination,
        size: val,
      };
      let tempParams = {
        page: this.pagination.page,
        rows: this.pagination.size,
      };
      this.getData(tempParams);
    },
    /** 分页参数-当前页改变 */
    handleCurrent(val) {
      this.pagination = {
        ...this.pagination,
        page: val,
      };
      let tempParams = {
        page: this.pagination.page,
        rows: this.pagination.size,
      };
      this.getData(tempParams);
    },
    /** 查询 */
    handleSearch() {
      this.pagination = {
        ...this.pagination,
        page: 1,
      };
      let tempParams = {
        ...this.searchForm,
        page: this.pagination.page,
        rows: this.pagination.size,
      };
      this.getData(tempParams);
    },
    /** 刷新页面 */
    handleRefresh() {
      this.reload();
    },
    /** 初始化表格高度 */
    initTableHeight() {
      if (this.$refs["tableContainer"]) {
        this.tableHeight = this.$refs["tableContainer"].offsetHeight;
      }
    },
    /** 全屏 */
    handleFullScreen() {
      const element = document.getElementById("tableContainer");
      this.isFull = !this.isFull;
      if (this.isFull) {
        this.$screenfull.request(element);
      } else {
        this.$screenfull.exit(element);
      }
    },
    /** 表格多选触发 */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /** 删除用户 */
    handleDelete(id) {
      /** 判断id是否存在，存在即单个删除。反之触发批量删除 */
      if (!id && id != 0) {
        if (this.multipleSelection.length > 0) {
          id = this.multipleSelection.map((item) => item.id);
        }
      }

      if (id || id == 0) {
        id = (id instanceof Array && id.join(",")) || id;

        /** 弹窗再次确认操作 */
        this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          /** 确认触发 */
          .then(async () => {
            /**
             * 请求接口删除数据
             */
            let res = await memberDelete(id);
            // this.$refs['tableBox'].clearSelection(); //删除操作请求失败，清空表格多选

            /** 判断当前页数据是否仅剩1条 */
            if (this.tableData.length == 1) {
              this.pagination = {
                ...this.pagination,
                page:
                  (this.pagination.page > 1 && this.pagination.page - 1) || 1, // 判断当前页是否为第一页，不为第一页则减少一页
              };
            }
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: res.msg,
              });
            }
            let tempParams = {
              ...this.searchForm,
              page: this.pagination.page,
              rows: this.pagination.size,
            };
            this.getData(tempParams);
          })
          /** 取消触发 */
          .catch(() => {
            this.$refs["tableBox"].clearSelection(); //删除操作取消，清空表格多选
            this.$message({
              type: "info",
              message: "已取消删除操作",
            });
          });
      }
    },
    /**
     * 状态开关
     */
    async handleStatusUpdate(id, status) {
      console.log(id);
      console.log(status);
      let params = {
        id,
        status,
      };
      let res = await memberUpdate(params, "post");
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: res.msg,
        });
      }

      this.getData();
    },
    /**
     * 解冻
     */
    async handleLoginStatus(id) {
      let res = await loginStatusUpdate(id);
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: res.msg,
        });
      }

      this.getData();
    },
    /** 重置用户密码 */
    handleResetPwd(id) {
      /** 弹窗再次确认操作 */
      this.$confirm("此操作将重置该用户密码为'zfwy@1234', 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        /** 确认触发 */
        .then(async () => {
          /**
           * 请求接口重置用户密码
           */
          let params = {
            id: id,
            password: "zfwy@1234",
          };
          let res = await memberUpdate(params, "post");
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "用户密码已重置!",
            });
            this.getData();
          }
        })
        /** 取消触发 */
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消重置密码操作",
          });
        });
    },
  },
};
</script>
